import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <p>
        Good Morning News is a revolutionary decentralized news platform powered by cutting-edge artificial intelligence. Our mission is to deliver credible, engaging, and relevant news content to our users while ensuring the highest standards of privacy and security.
      </p>
      <p>
        Built on the Ethereum blockchain, Good Morning News harnesses the power of decentralization to provide a trustless environment, free from censorship and manipulation. Our platform's AI-driven algorithms generate insightful news articles, enabling readers to stay informed and up-to-date on the latest global developments.
      </p>
      <p>
        Join us as we redefine the future of news consumption and empower our community with access to reliable information on a decentralized platform that belongs to everyone.
      </p>
    </div>
  );
};

export default About;
