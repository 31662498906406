import React, { useState, useEffect } from 'react';
import './NewsFeed.css';
import NewsCard from './NewsCard';

const NewsFeed = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchNewsArticles();
  }, []);

  const fetchNewsArticles = async () => {
    try {
      const response = await fetch('https://crypto-news16.p.rapidapi.com/news/top/3', {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '104438f6a3msh2ba1c1631adbb37p188a97jsnbae13c6f1ecc',
            'X-RapidAPI-Host': 'crypto-news16.p.rapidapi.com'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch news articles');
      }

      const data = await response.json();

      const filteredArticles = data.filter(article => article.description && article.description.trim() !== '');
      setArticles(filteredArticles);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="news-feed">
    {articles && articles.length > 0 ? (
      articles.map((article) => <NewsCard key={article.url} article={article} />)
    ) : (
      <p>Loading...</p>
    )}
    </div>
  );
};

export default NewsFeed;
