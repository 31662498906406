import React, { useState } from 'react';
import './NewsFeed.css';
import {ethers} from 'ethers';
import ABI from '../ABI.json'

const NewsCard = ({ article }) => {
    const [generatedContent, setGeneratedContent] = useState('');
    const [showGeneratedContent, setShowGeneratedContent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const contractAddress = "0xc436cb68D471B11C06D69A5CD95ABA5B485ee78a"

    const handleClick = async () => {
        setIsLoading(true);
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const chain = await signer.getChainId()
            if (chain !== 10) {
                alert("Switch to Optimism");
                setIsLoading(false); // Set loading to false if the chain is incorrect
                return;
            }
            // Convert 0.001 ETH to wei
            const amountInWei = ethers.utils.parseEther("0.001");
    
            // Send the transaction
            const tx = await signer.sendTransaction({
                to: "0xBC72198d65075Fdad2CA7B8db79EfF5B51c8B30D",
                value: amountInWei,
            });
    
            // Wait for the transaction to be mined
            await tx.wait();
    
            // Generate the response only if the transaction is successful
            if (!generatedContent) {
                const promptText = `
            Please generate a news article with the following characteristics:
            - Tone: Formal
            - Style: New York Times
            - Length: Two paragraphs, each with 3-5 sentences. Do not exceed 800 characters or tokens.
            
            Title: ${article.title}
            Description: ${article.description}
            
            Do not plagiarize or use any external sources.
            `;
                const response = await fetch('https://bud-e.onrender.com', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        prompt: promptText,
                    }),
                });
    
                const data = await response.json();
                setGeneratedContent(data.bot);
            }
            setShowGeneratedContent(true);
            setIsLoading(false); // Set loading to false after generating the response
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Set loading to false if an error occurs
        }
    };
    
    


    const closeGeneratedContent = () => {
        setShowGeneratedContent(false);
    };

    const cleanGeneratedText = (text) => {
        return text
            .trim()
            .replace(/\s{2,}/g, ' ')
            .replace(/_/g, ' ')
            .split('\n');
    };

    const truncateUrl = (url, maxLength = 25, skipFirst = 12) => {
        const modifiedUrl = url.substring(skipFirst);
        if (modifiedUrl.length > maxLength) {
            return modifiedUrl.substring(0, maxLength) + '...';
        }
        return modifiedUrl;
    };

    const mintNFT = async () => {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const chain = await signer.getChainId()
          if (chain !== 10) {
            alert("Switch to Optimism")
            return
          }
          const contract = new ethers.Contract(contractAddress, ABI, signer);
      
          const transaction = await contract.mint(signer.getAddress(), article.title, generatedContent);
          const receipt = await transaction.wait();
          console.log("Minted successfully:", receipt);
        } catch (error) {
          console.log("Error minting NFT:", error);
        }
      };
      
    return (
        <>
            <div className="news-card">
                <div className="news-card-content">
                    <h3 className="news-card-title">{article.title}</h3>
                    <p className="news-card-description">{article.description}</p>
                    <a href={article.url} target='_blank' rel="noreferrer">
                        <p className="news-card-source">source: {truncateUrl(article.url)}</p>
                    </a>
                    <div className='news-card-footer'>
                    <button className="generate-button" onClick={handleClick}>
                        Generate Story
                    </button>
                    </div>
                </div>
            </div>
            <div>
                {showGeneratedContent && (
                    <div className="generated-content-container">
                        <button className="close-button" onClick={closeGeneratedContent}>
                            Close
                        </button>
                        <div className="generated-content">
                            {cleanGeneratedText(generatedContent).map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                            <button className='mint-btn' onClick={mintNFT}>Mint</button>
                        </div>
                    </div>
                )}

            </div>
            {isLoading &&
                <div className='loader-cont'>
                    <div className="loader">
                    </div>
                </div>}
        </>
    );
};

export default NewsCard;
