import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Nav';
import NewsFeed from './components/NewsFeed';
import About from './components/About'
import Gallery from './components/Gallery';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<NewsFeed />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;
