import React from 'react';
import './Header.css';
import logo from '../images/logo.png';
import { useState } from "react";
import {ethers} from 'ethers';

const Header = () => {

  const [connected, setConnected] = useState(false);
  const [name, setName] = useState("please sign in");

  const connect = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner()
      const chain = await signer.getChainId()
      if (chain !== 10) {
        alert("Switch to Optimism")
        return
      }
      await signer.signMessage("Welcome to Good Morning News!");

      const { ethereum } = window;
      if(ethereum) {
        const ensProvider = new ethers.providers.InfuraProvider('mainnet');
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const displayAddress = address?.substr(0, 6) + "...";
        const ens = await ensProvider.lookupAddress(address);
        if (ens !== null) {
          setName(ens)
        } else {
          setName(displayAddress)
        }
      } else {
        alert('no wallet detected!')
      }
      setConnected(true)
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <header className="header">

      <img src={logo} alt="Logo" />
      <h1>Good Morning News</h1>
    
      <button onClick={connect}>
        {!connected && (
          <p>connect</p>
        )}
        {connected && (
          <p>{name}</p>
        )}
      </button>
    </header>
  );
};

export default Header;
