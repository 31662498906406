import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import GMNMarketplaceABI from '../MarketplaceABI.json';
import ABI from '../ABI.json';
import './Gallery.css';
import './Modal.css';

const contractAddress = "0xc436cb68D471B11C06D69A5CD95ABA5B485ee78a";
const marketplaceAddress = "0x9cf9F7FdE8A10B52ebA59ABBb456bEdf45755270";

function Gallery() {
  const [nfts, setNfts] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = (nft) => {
    setSelectedNFT(nft);
    setShowModal(true);
  };
  

  useEffect(() => {
    setIsLoading(true);
    loadNfts().finally(() => setIsLoading(false));
  }, []);

  const loadNfts = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, ABI, provider);
    const marketplaceContract = new ethers.Contract(marketplaceAddress, GMNMarketplaceABI, provider);
    const totalSupply = await contract.getTotalSupply(); // Use getTotalSupply() function
    const fetchNftData = async (id) => {
      const tokenURI = await contract.tokenURI(id);
      const tokenData = JSON.parse(atob(tokenURI.split(",")[1]));
      const imageURI = tokenData.image;
      const offer = await marketplaceContract.tokenIdToOffer(id);
      const isForSale = offer.isForSale;
      const askingPrice = offer.askingPrice;

      return {
        id,
        imageURI,
        isForSale,
        askingPrice,
      };
    };

    const nftDataPromises = [];
    for (let i = 1; i <= totalSupply; i++) {
      nftDataPromises.push(fetchNftData(i));
    }

    const nftDataArray = await Promise.all(nftDataPromises);
    setNfts(nftDataArray);
  };

  const approveMarketplace = async (tokenId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const chain = await signer.getChainId()
    if (chain !== 10) {
      alert("Switch to Optimism")
      return
    }
    const contract = new ethers.Contract(contractAddress, ABI, signer);
    const transaction = await contract.approve(marketplaceAddress, tokenId);
    await transaction.wait();
  };

  const createOffer = async (tokenId, price) => {
    await approveMarketplace(tokenId);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const chain = await signer.getChainId()
    if (chain !== 10) {
      alert("Switch to Optimism")
      return
    }
    const marketplaceContract = new ethers.Contract(marketplaceAddress, GMNMarketplaceABI, signer);
    const transaction = await marketplaceContract.createOffer(tokenId, ethers.utils.parseEther(price.toString()));
    await transaction.wait();
    loadNfts()
  };

  const buyCard = async (tokenId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const chain = await signer.getChainId()
    if (chain !== 10) {
      alert("Switch to Optimism")
      return
    }
    const marketplaceContract = new ethers.Contract(marketplaceAddress, GMNMarketplaceABI, signer);
    const offer = await marketplaceContract.tokenIdToOffer(tokenId);
    const askingPrice = offer.askingPrice;
    const isForSale = offer.isForSale;
    if (isForSale) {
      const transaction = await marketplaceContract.buyCard(tokenId, { value: askingPrice });
      await transaction.wait();
    } else {
      console.log("Card is not for sale");
    }
  };

  const selectNFT = (nft) => {
    if (selectedNFT && selectedNFT.id === nft.id) {
      setSelectedNFT(null);
    } else {
      setSelectedNFT(nft);
    }
  };

  return (
    <div>
      <div className={`loading-overlay ${isLoading ? 'visible' : 'hidden'}`}>
        <div className="spinner"></div>
      </div>
      <div className="gallery">
      {nfts.slice(2).map((nft) => (  // I hid the first 2 nfts since they were kinda fucky
          <div className="nft" key={nft.id}>
            <img
              src={nft.imageURI}
              alt={`NFT ${nft.id}`}
              onClick={() => openModal(nft)}
              style={{ width: "300px", height: "300px", cursor: "pointer" }}
            />
            <div className="nft-info">
              {nft.isForSale ? (
                <p style={{ textAlign: "center" }}>For Sale: {ethers.utils.formatEther(nft.askingPrice)} ETH</p>
              ) : (
                <p style={{ textAlign: "center" }}>Not for sale</p>
              )}
            </div>
            {showModal && (
  <div className="modal" onClick={() => setShowModal(false)}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <img
        src={selectedNFT.imageURI}
        alt={`NFT ${selectedNFT.id}`}
        style={{ width: "100%", height: "auto", marginBottom: "20px" }}
      />
      <div className="actions">
        <input onChange={(e) => setPrice(e.target.value)} type="number" placeholder="Enter price (ETH)" />
        <div>
          <button
            onClick={async () => {
              await createOffer(selectedNFT.id, price);
              setShowModal(false);
            }}
          >
            Sell
          </button>
          {selectedNFT.isForSale && (
            <button
              onClick={() => {
                buyCard(selectedNFT.id);
                setShowModal(false);
              }}
            >
              Buy
            </button>
          )}
        </div>
      </div>
      <span className="modal-close" onClick={() => setShowModal(false)}>
        &times;
      </span>
    </div>
  </div>
)}

          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
